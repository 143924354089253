.SolutionSection{
    padding: 10px;
    background-color: #FAF4F4;
    padding-top: 50px;
    padding-bottom: 50px;

}
.SolutionSectionImageCol{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.SolutionSectionImageCol img{
    max-width: 350px;
}
.FindMoreBtn button .Nav-Link{
    background: linear-gradient(#AE141B, #ED1C24);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.FindMoreBtn button:hover .Nav-Link{
    background: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;}
.SolutionSectionTextCol{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
}
.SolutionSectionTextCol h1,
.SolutionSectionTextCol span{
    padding: 10px;
}
.SolutionHeader{
    background: linear-gradient(279.62deg, #ED1C24 4.5%, #E21B23 20.28%, #87101E 97.32%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-weight: 700 !important;
}
.SolutionSectionTextCol span{
    text-align: start;
    font-weight:400;
}

.AboutSection{
    padding: 10px;
    background-color: white;
    padding-top: 50px;
    padding-bottom: 50px;
}
.TeamDescription{
    font-size:32px;
    font-weight: 400;
}
.TeamCard{
    /* background: linear-gradient(180deg, #ED1C24 4.5%, #E21B23 20.28%, #87101E 97.32%); */
    background: #E31B22;
    

    color: white;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border-top-left-radius: 50%;
    border-top-right-radius:50%; */
    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    border-radius: 50px;
    min-height: 220px;
    max-width: 200px;
    min-width: 200px;
    border-radius: 100px, 100px, 38px, 38px;
}
.TeamCard .Circle{
    max-width: 140px;
    max-height: 140px;
    border-radius: 50px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow:hidden ;
    box-shadow: rgba(0, 0, 0, 0.35) 6px 5px 0px;
    position: relative;

}
.TeamCard .Circle image{
    object-fit: cover;
}
.TeamName{
    margin-top: 10px;
    margin-bottom: 5px;
}
.TeamRole{
    font-weight: 200;
    font-size: 18px;
}
.TeamSection{
    padding: 10px;
    background-color: #FAF4F4;
    padding-top: 50px;
    padding-bottom: 50px;
}
.ClientsSection{
    background-color: white;
    padding-top: 50px;
}
.ClientCardContainer{
    width: 150px;
    padding: 20px;
}
.ClientCard{
    padding: 5px;
    padding-top: 20px;
    padding-bottom:20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #DFC8C8;
    border-radius:30px;
    
}
.ClientCard img{
    height: 58px;
    object-fit: contain !important;
}
.ClientCardsSliderMain{
    width: 99.5% !important;
}
.ClientCardsSlider{
    margin-top: 50px;
    margin-bottom: 50px;
}

.WhyUsSection{
    min-height: 600px;
    background-image: url('../../Assets/Images/FooterImage.jpg');
    background-size: cover;
    background-position: center;
    position: relative;

}
.OverlayFooter{
    z-index: 10;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--color-light-red);
    background-color: #ff0008;
    opacity: 0.80;
}
.WhyUsSection .CustomContainer{
    position: relative;
    z-index: 120 !important;
}
.WhyUsCol{
    padding: 10px;
    padding-top: 10%;
    text-align: start;
    color: white;
    padding-left: 25px !important;
    padding-right: 25px !important;
}

.WhyChooseSpan{
    font-size: 80px;
    font-weight: 700;
    display: flex;
    justify-content: start;
    line-height: 96px;
}
.WhyChooseUsText{
    font-size: 15px;
    line-height: 23.51px;
    position: relative;
    top: 20px;
    padding-right: 20px;

}
.BottomFooter{
    position: relative;
    padding-top: 500px;
}
.BottomFooter .WhyUsSection{
    position: absolute;
    top: 100px;
    z-index: 8;
    width: 100%;
    display: flex;
    justify-content: center;
}
.BottomFooter footer{
    z-index: 10;
    position: relative;
}
@media (max-width:1200px) {
    .WhyChooseSpan{
        font-size:60px;
        line-height: normal;

    }
}

@media (max-width:768px) {
    .WhyChooseUsText{
        padding-top: 0 !important;
    
    }
    .WhyChooseSpan{
        font-size:40px;
        line-height: normal;

    }
}