.JobsSection {
    padding: 20px;
}

.JobsHeader {
    text-align: center;
    margin-bottom: 20px;
}

.job-card {
    border-radius: 10px;
    padding: 20px;
    background-color: #f8f9fa;
    transition: box-shadow 0.3s;
    text-align: start;
}
.card-image{
    display: flex;
    justify-content: start;
}
.card-image svg{
    font-size: 30px;
    margin-right:20px ;
}
.card-body{}
.job-card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #343a40;
    margin-bottom: 15px;
}

.card-text {
    font-size: 1rem;
    color: #6c757d;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.show-more {
    color: #007bff;
    cursor: pointer;
    margin-left: 5px;
    font-weight: 500;
}

.show-more:hover {
    text-decoration: underline;
}

ul.list-unstyled {
    margin-top: 10px;
    padding-left: 15px;
}

.requirements h6 {
    font-weight: bold;
    margin-top: 15px;
}

.requirements ul {
    padding-left: 15px;
}

