.News{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.LatestNewsCard{
    background: #FEEFEF;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 25px;
    position: relative;
}
.AllNews{

}
.LatestNewsImageContainer {
    border-radius: 30px;
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 50px;
    margin-left: 20px;
    margin-right: 20px;
}
.LatestNewsImageContainer img{
}
.LatestNewsImageContainer {
    box-shadow: 0px 7.38px 10.14px 0px #9A2D2D40;

}
.AllNews>.container{
    padding-left: 70px;
    padding-right:70px;
}

.LatestNewsCardTags{
    display: flex;
    justify-content: start;
    align-items: center;
    flex-wrap: wrap;
}
.TagItem{
    font-size: 16.57px;
    font-weight: 400;
    text-align: center;
    color: black;
    background-color: #E8CCCC;
    padding: 2px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 5px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease-in-out;
}
.TagItem:hover{
    background:#e3d3d3 ;
}
.LatestNewsTitle{
    margin-top: 30px;
    margin-bottom: 30px;
    padding-left:10px;
    padding-right: 50px;
    text-align: start;
    font-size: 20px;
    font-weight: 500;
    line-height: 26.99px;

}
.LatestNewsDescription{
    text-align: start;
    padding-left: 10px;
}
.LatestNewsCol{
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    padding: 20px;
}
.LatestNewsShowMore{
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-size: 14.73px;
    font-weight: 500;
    line-height: 19.88px;
    text-align: left;
    cursor: pointer;
    color: black;
    transition: all 0.2s ease-in-out;

}
.LatestNewsShowMore svg{
    width: 12px;
    height: 12px;
}
.LatestNewsShowMore:hover{
    color: red;
}
.NewsCardsContainer{
    margin-bottom: 50px;
}
.NewsCardItem{
    padding: 20px;
}
.NewsCardItem .NewsCard{
    min-width: 300px;
    max-width: 345px;
    border-radius:25px;
    background-color: #FEEFEF;
    box-shadow: 0px 3.64px 14.38px 0px #CA585840;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    min-height: 360px;
}
.NewsCardItem .NewsCard .NewsCardImageContainer{
    border-radius: 30px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: 0px 3.64px 5.01px 0px #9A2D2D40;
    max-height: 190px !important;

}
.NewsCardItem .NewsCard .NewsCardImageContainer img{
    object-position: center;

}
.NewsCardItem .NewsCard .NewsCardTitle{
    padding-left: 20px;
    padding-right: 20px;
    font-size: 14.56px;
    font-weight: 500;
    line-height: 19.66px;
    text-align: center;
    margin-bottom: 10px;
    width: 100%;
    
    /* Limit the title to two lines */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
    /* Add ellipsis at the end */
    text-overflow: ellipsis;
}



@media (max-width:468px) {
    .LatestNewsImageContainer{
        margin-top: 20px;
        margin-bottom: 20px !important;
    }
    .AllNews>.container {
        padding-left: 30px !important;
        padding-right:30px !important;
    }
}

.LatestNewsCard {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.LatestNewsCard.transitioning {
    opacity: 0.5;
}


.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 50px;

}

.page-nav {
    /* background: #f1f1f1; */
    /* border: 1px solid #ddd; */
    background-color: transparent;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
    color: black;
}

.page-nav:disabled {
    background: none;
    cursor: not-allowed;
}

.page-number {
    /* background: #f1f1f1; */
    background: none;
    border: none;
    border-radius: 50%;
    width: 40px;
    height:40px;
    padding: 5px;
    margin: 0 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.page-number.active {
    background: #feefef;
    color: black;
}

/* Blog details */
.SingleBlogSection{
    margin-top: 100px;
    margin-bottom: 50px;
}           
.SingleBlogImageContainer{
    margin-top: 10px;
    width: 100%;
    padding: 20px;
    border-radius: 15px;
    overflow: hidden;
    /* background-color: #4f98c0; */
}
.SingleBlogImageContainer img{
    border-radius: 15px;
    max-height:400px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
    object-fit: contain;
}
.SingleBlogTitle{
    padding: 20px;
    margin-bottom: 0;
    padding-bottom: 0px;
    font-size: 25px;
    /* font-family: 'Josefin'; */

}
.SingleBlogTextContainer p{
    padding: 20px;
    padding-bottom: 0;
    margin-bottom: 0;
}

.ShareSingleBlogPage{
    margin-top: 0;
    padding-left: 20px;
    padding-right:20px;
}
.ShareSingleBlogPage{
    font-size: 18px;
}
.ShareSingleBlogPage .social-icons a svg{
    width: 25px;
    height: 25px;
}
.social-icons a {
    font-size: 1.2rem;
}

.BackToNewsBtn{
    color: var(--color-light-red) !important;
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    transition: all 0.2s ease-in-out;
}
.BackToNewsBtn:hover{
    color: var(--color-dark-red) !important;

}
.SingleBlogSection{
    text-align: start;
}
.NewsDesc h5{
    margin-top: 10px;
}