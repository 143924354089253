/* BackgroundVideo.css */
.video-container {
    position: relative;
    width: 100%;
    height: 90vh;
    overflow: hidden;
  }
  
  .background-video {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
  
  .content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    color: white;
    text-align: center;
    /* background-image: linear-gradient( 0deg ,#ae141ce9 , #ed1c2385 , #ae141ce6); */
  }
  .FirstSectionHeader .Integrated{
    font-size:80px;
    font-weight: 400;
  }
  .FirstSectionHeader .Solutions{
    font-size:100px;
    font-weight: 700;
  }
  .WeStrive{
    max-width: 626px;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;

  }
  .FindMoreBtn{
    margin-top:20px;
  }
  .FindMoreBtn button{
    background-color: white;
    color: var(--color-light-red);
    border-radius: 30px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    font-weight: 700;
  }
  .VideoCurve{
    position: absolute;
    bottom: 0px;
    border-top-left-radius: 150px;
    border-top-right-radius: 150px;
    width: 100%;
    height: 110px;
    background-color: white;
  }
  @media (max-width:1400px) {
    .VideoCurve{
      position: absolute;
      bottom: -44px;
    }
  }