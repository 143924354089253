.ServicesFirstSection {
    margin-top: 50px;
    margin-bottom: 50px;
}

.ServicesHeadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.FirstServicesSectionHeader .HeaderServices {
    font-size: 32px;
    font-weight: 700;
    line-height: 38.4px;
    text-align: center;
}

.UnderLineServices {
    margin-top: 10px;
    height: 6px;
    width: 200px;
    background-image: linear-gradient(279.62deg, #ED1C24 4.5%, #E21B23 20.28%, #87101E 97.32%);
    border-radius: 20px;
}

.AfterLineText {
    margin-top: 10px;
    max-width: 657px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19.2px;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
}

.ServicesNewCard {
    width: 100%;
    max-width: 350px;
    background: #FEEFEF;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
    border-radius: 24px !important;

}

.ServicesNewCard .ImageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    width: 100%;
    max-height: 180px;
    padding: 10px;
}




.ReadMoreServiceContainer {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.SerciceNewCardContainer{
    height: 100%;
}
.HeaderServiceNewCard{
    font-size: 24px;
    font-weight: 700;
    line-height: 24.96px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 5px;
    }
.ServiceNewCardShowMore{
    box-shadow: 0px 4px 4px 0px #F800005C;
    background: linear-gradient(279.62deg, #ED1C24 4.5%, #E21B23 20.28%, #87101E 97.32%);
    padding-left: 20px !important;
    padding-right: 20px !important;
}
.ReadMoreNewServiceContainer button{
    margin-top: 0 !important;
}
.ServiceCardShowMore{
    width: 120px;
}
.SelectedServicesCard{
    transition: all 0.2s ease-in-out;
}
.SelectedServicesCard{
    background: #DD1A23;

}
.SelectedServicesCard .ServicesCardHr{
    background-color: #DFC8C8;
}
.SelectedServicesCard .HeaderServiceNewCard{
    color: white;
}
.SelectedServicesCard .ServiceCardShowMore{
    background-color: black !important;
    background-image: none;
    color: white;
}

.SubServiceCard{
    width: 153.11px;
    height: 41.54px;
    border-radius: 23px;
    border: 1px solid #D80808;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-left: 65px;
    background-color: white;
    box-shadow: 0px 4.75px 7.83px 0px #A2282875;
    transition: all 0.1s linear;
    cursor: pointer;
}
.SubServiceCard .SubServiceTitle{
    font-size: 13px;
    font-weight: 600;
    line-height: 14.24px;
    text-align: left;
    padding-right: 5px;


}
.SubServiceImage{
    width: 40%;
    background: #D80808;
    height: 41.54px;
    border-radius: 23px;
    position: absolute;
    left: -2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.SubServiceImage img{
    height: 30px;
}
.SelectedSubService {
    background-color: black;
    transition: all 0.1s linear;
}
.SelectedSubService .SubServiceTitle{
    color: white;
}
.HeaderServiceCard{
    text-align: start;
}


.SelectedSubServiceSection{
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.SelectedSubServiceSection .container{
    background: linear-gradient(279.62deg, #ED1C24 4.5%, #E21B23 20.28%, #87101E 97.32%);
    width: 98%;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius:  64px;
    border-bottom-right-radius:  64px;
    overflow: hidden;

}
.SelectedSubServiceImageContainer{
    overflow: hidden;
    
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border-bottom-left-radius:  64px;
    border-bottom-right-radius:  64px;
}
.SelectedSubServiceTextCol{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    padding-left: 100px;
    padding-right: 70px;
}

.SelectedSubServiceTextCol{
    padding-bottom: 50px !important;
    padding-top: 50px !important;
    overflow: hidden;
}

.SelectedSubServiceHeader{
    color: white;
    font-size: 24.46px;
    font-weight: 700;
    line-height: 30.09px;
    text-align: left;
    
}
.SubServiceUnderline{
    height: 2.29px;
    background-color: white;
    width: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 30px;
}
.SelectedSubServiceDescription{
    display: flex;
    font-size: 18px;
    font-weight: 300;
    line-height: 20px;
    text-align: start;
    width: 100%;
    color: white;

}
.SelectedServiceMainImageCol{
    padding: 0;
    max-height: 450px;

}
.SelectedSubServiceImageContainer{
    height: 100%;
    height: 450px;
}
.SelectedSubServiceImageContainer img{
    filter: grayscale(100%) !important;
}
.AfterShowText{
    margin-top: 50px;
    margin-bottom: 50px;
}
.HeaderWorkSamples{
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 24.46px;
    font-weight: 700;
    line-height: 30.09px;
    text-align: center;

}
.WorkSampleImageCol{
    transition: all 0.2s ease-in-out;
}
.WorkSampleImageCol:hover{
    width: 50%;
    margin-top: 10px;
    margin-bottom: 10px;
}
@media (min-width:1024px) {
    .SelectedSubServiceSection .container{
        padding-right: 0;
        padding-left: 0;
        border-top-left-radius: 176px;
        border-top-right-radius: 176px;
        border-bottom-left-radius:  64px;
        border-bottom-right-radius:  64px;
    }
    .SelectedSubServiceImageContainer{
        overflow: hidden;
        
        border-top-left-radius: 176px;
        border-top-right-radius: 176px;
        border-bottom-left-radius:  64px;
        border-bottom-right-radius:  64px;
    }
    .SelectedSubServiceTextCol{
        padding-bottom: 00px !important;
        padding-top: 00px !important;
    }
    
}
.ServicesCard {
    height: 380px;
    justify-content: space-between !important;
}
@media (max-width:1260px) {
    .SelectedServiceMainImageCol{
        max-height: 500px;
    }
}
@media (max-width:468px) {
    .SelectedServiceMainImageCol{
        max-height: 250px;
    }
    .SelectedSubServiceImageContainer{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SelectedSubServiceImageContainer img{
        width: auto !important;
    }
}
@media (max-width:350px) {
    .SubServiceCard{
        padding-left:50px ;
    }
    .SubServiceTitle{
        font-size: 12px !important;

    }
    .SelectedSubServiceTextCol{
        padding-left: 50px;
        padding-right: 20px;
    }
}