.TopNav{
    background-color: var(--color-dark-white);
    /* background-color: #f4f4f4; */
}
.TopNavCol{
    display: flex;
    align-items: center;
    justify-content: start;
}
.NavLocation{
    font-size: 14px;
    padding: 10px;
    text-align: start;
}
.NavCallUs{
    padding: 10px;
    font-size: 14px;
    text-align: start;

}
.TopNavCol span{
    font-weight: 100;
    padding-left: 15px;
}
.AddressNav .PhoneNumber{
    font-weight: 700;
}
.AddressNav svg{
    color: var(--color-light-red);
}
.LogoContainer{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: start;
    align-items: end;
}
.LinksNav{
    display: flex;
    justify-content: end;
    align-items: center;
}
.LinksNav ul{
    padding: 0;
    margin: 0;
}
.LinksNav ul li{
    margin-left: 10px;
    margin-right: 10px;
    font-weight: 500;
}
.ActiveLink{
    color: var( --color-light-red) !important;
    font-weight: 700 !important;
}

.NavRow{
    display: flex;
    justify-content: space-between !important;
}
.NavContactBtn{
    background-image: linear-gradient(#AE141B, #ED1C24) ;
    border-radius: 30px;
    padding-right: 20px;
    padding-left: 20px;
    box-shadow: 0px 4px 4px 0px #F800005C;
    white-space: nowrap;

    
}
.Nav-Link{
    color: black;
    text-decoration: none;
    
}
.ScrolledNav {
    position: fixed;
    width: 100%;
    background: white;
    z-index: 99999;
    box-shadow: rgba(100, 100, 111, 0.2) 0 1px 20px 5px;
}

.MainNav {
    background-color: #fff;
    /* padding: 10px 0;  */
    position: relative;
    z-index: 1000;
    transition: all 0.3s ease-in-out;
}

.MainNav.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: rgba(151, 30, 30, 0.2) 0px 7px 29px 0px;
    animation: fadeInDown 0.5s ease;
}

@keyframes fadeInDown {
    from {
        opacity: 0;
        transform: translateY(-10%);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.OverlayMenu {
    position: fixed;
    height: 100%;
    width: 0;
    top: 0;
    right: 0;
    background-color:white;
    overflow-x: hidden;
    transition: 0.5s;
    z-index: 200;
    top: 77px;
    box-shadow: rgba(151, 30, 30, 0.2) 0px 7px 29px 0px;
}   

.OverlayMenu.open {
    width: 250px;
}

.OverlayLinks {
    list-style: none;
    padding: 0;
    margin-top: 100px;
    text-align: center;
}

.OverlayLinks li {
    margin: 20px 0;
}

.OverlayLinks a {
    color: black;
    font-size: 20px;
    text-decoration: none;
    transition: color 0.3s;
}

.OverlayLinks a:hover {
    color: #323232;
}

/* Close button */
.CloseButton {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 30px;
    color: #000;
    border: none;
    background: none;
    cursor: pointer;
}

.MenuButton {
    background: none;
    border: none;
    color: #000;
    font-size: 24px;
}
.MenuButtonCol{
    margin-right: 10px;
}
/* Show the menu button only on smaller screens */
@media (max-width: 768px) {
    .LinksNav {
        display: none;
    }

    .MenuButton {
        display: block;
    }
}