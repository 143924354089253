.gallery-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    align-items: center;
  }
  
  .gallery-item {
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
    height: 100%;
  }
  
  .gallery-image {
    width: 100%;
    display: block;
  }
  .gallery-item img{
    
    height: 100%;
  }
  .hover-effect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .gallery-item:hover {
    transform: scale(1.2);
    z-index: 20;
  }
  
  .gallery-item:hover .hover-effect {
    opacity: 1;
  }
  
  .hover-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .pagination {
    grid-column: span 3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  
  .pagination span {
    margin: 0 5px;
    cursor: pointer;
    font-size: 18px;
  }
  
  .pagination .active {
    font-weight: bold;
  }
  .video-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  .close-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
    color: white;
  }
  .video-gallery-container{
    position: relative;

    width: 80%;
    max-width: 900px;
  }
  .gallery-item{
    position: relative;
  }
  .play-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 3rem; 
    opacity: 0.8;
    pointer-events: none; 
  }
  @media (max-width: 480px) {
    .gallery-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

    }
    .gallery-item{
      margin-top: 5px ;
      margin-bottom: 5px ;
    }
    .gallery-item:hover{
      transform: scale(1.05) !important;
    }
  }
  